import React from "react";
import SimpleBackdrop from "../common/SimpleBackdrop";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import SimpleCard from "../common/SimpleCard";
import {IntlProvider, LocalizationProvider} from "@progress/kendo-react-intl";
import {Grid} from "@progress/kendo-react-grid";
import * as _ from "lodash";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import {GoogleCloudPI, ReportsAPI} from "../../api/service";
import SnackbarContentWrapper from "../common/SnackBar";
import Snackbar from "@material-ui/core/Snackbar";

class LocationList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data: [],
            originalData: [],
            skip: 0,
            take: 5,
            filter: {
                logic: "and",
                filters: [
                    {field: "name", operator: "contains", value: ""}
                ]
            },
            snackbar: {
                open: false,
                type: 'info',
                message: ''
            }
        };
    }

    // <editor-fold defaultstate="collapsed" desc="Start">

    getLocationList = () => {
        let url = "/adm/reports/location/list";
        let data = {
            projectId: this.props.projectId
        };
        ReportsAPI.post(url, data)
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data,
                        originalData: response.data
                    });
                }
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoading: false}))
    };

    componentDidMount() {
        this.getLocationList();
    }

    pageChange = (event) => {
        this.setState({
            skip: event.page.skip,
            take: event.page.take
        });
    };

    // </editor-fold>

    // <editor-fold defaultstate="collapsed" desc="Download document">

    getDocumentLocation = (locationId) => {
        this.setState({isLoading: true});
        let url = "/adm/reports/location/answers";
        let data = {
            projectId: this.props.projectId,
            locationId: locationId
        };
        ReportsAPI.post(url, data)
            .then(response => {
                if (response.success) {
                    this.generateDocument(response.data, locationId);
                } else {
                    this.setState({isLoading: false})
                    this.handleOpenSnackbar('Ocurrió un error al obtener los datos', 'error');
                }
            })
            .catch(error => console.log(error))
    };

    generateDocument = (answers, locationId) => {
        let url = "/adm/google-cloud/document/generate";
        let data = {
            projectId: this.props.projectId,
            locationId: locationId,
            answers: answers
        };
        GoogleCloudPI.post(url, data)
            .then(response => {
                if (response.success) {
                    const link = document.createElement('a');
                    link.href = response.data;
                    link.target = '_blank';
                    link.click();
                    link.remove();
                } else {
                    this.handleOpenSnackbar('Error al generar el archivo: ' + response.message, 'error');
                }
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoading: false}))
    };

    // </editor-fold>

    // <editor-fold defaultstate="collapsed" desc="Snackbar">
    handleCloseSnackbar = () => {
        this.setState({
            snackbar: {
                open: false,
                type: 'info',
                message: ''
            }
        });
    };

    handleOpenSnackbar = (message, type) => {
        this.setState({
            snackbar: {
                open: true,
                type: type,
                message: message
            }
        });
    };

    // </editor-fold>

    render() {
        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.snackbar.open}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackbar}
                >
                    <SnackbarContentWrapper
                        onClose={this.handleCloseSnackbar}
                        variant={this.state.snackbar.type}
                        message={this.state.snackbar.message}
                    />
                </Snackbar>
                <SimpleBackdrop
                    open={this.state.isLoading}
                />
                <React.Fragment>
                    <CssBaseline/>
                    <Container maxWidth="xl">
                        {this.state.isLoading ? '' :
                            <div className={'pt-5'}>
                                <div className={'pt-4'}>
                                    <SimpleCard
                                        cardHeader={
                                            {
                                                title: this.props.projectName,
                                                subheader: ''
                                            }
                                        }
                                        cardContent={
                                            <LocalizationProvider language="es-ES">
                                                <IntlProvider locale="es">
                                                    <Grid
                                                        data={this.state.data.slice(this.state.skip, this.state.take + this.state.skip)}
                                                        skip={this.state.skip}
                                                        take={this.state.take}
                                                        total={this.state.data.length}
                                                        style={{height: "auto"}}
                                                        pageable={true}
                                                        onPageChange={this.pageChange}
                                                        filterable
                                                        filter={this.state.filter}
                                                        onFilterChange={(e) => {
                                                            let filterValue = '';
                                                            if (e.filter !== null) {
                                                                filterValue = e.filter.filters[0].value;
                                                            }
                                                            if (filterValue === '') {
                                                                let originalData = this.state.originalData;
                                                                this.setState({
                                                                    data: originalData,
                                                                    filter: e.filter
                                                                });
                                                            } else {
                                                                let filteredData = _.filter(this.state.originalData, function (o) {
                                                                    let text = o.name.toLowerCase();
                                                                    return text.indexOf(filterValue.toLowerCase()) > -1;
                                                                });
                                                                this.setState({
                                                                    data: filteredData,
                                                                    filter: e.filter
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        <Column field="id" title="Código" filterable={false}/>
                                                        <Column field="code" title="Clave" filterable={false}/>
                                                        <Column field="name" title="Nombre"/>
                                                        <Column field="latitude" title="Latitud" filterable={false}/>
                                                        <Column field="length" title="Longitud" filterable={false}/>
                                                        <Column title="Documento" filterable={false}
                                                                cell={
                                                                    props => {
                                                                        let item = props.dataItem;
                                                                        return <td
                                                                            className="k-command-cell text-center">
                                                                            <Tooltip title={'Generar documento'}>
                                                                                <IconButton color="primary"
                                                                                            aria-label="locations"
                                                                                            component="span"
                                                                                            disabled={!item.existsData}
                                                                                            onClick={() => {
                                                                                                this.getDocumentLocation(item.id)
                                                                                            }}
                                                                                >
                                                                                    <CloudDownloadIcon/>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </td>
                                                                    }
                                                                }
                                                        />

                                                    </Grid>
                                                </IntlProvider>
                                            </LocalizationProvider>
                                        }
                                    />
                                </div>
                            </div>
                        }
                    </Container>
                </React.Fragment>
            </div>
        );
    }

}

export default LocationList;