import React from "react";
import SimpleCard from "../common/SimpleCard";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import {IntlProvider, load, LocalizationProvider, loadMessages} from '@progress/kendo-react-intl';
import esMessages from '../../globalization/es.json';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';
import numbers from 'cldr-numbers-full/main/es/numbers.json';
import currencies from 'cldr-numbers-full/main/es/currencies.json';
import caGregorian from 'cldr-dates-full/main/es/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/es/dateFields.json';
import timeZoneNames from 'cldr-dates-full/main/es/timeZoneNames.json';
import {Grid, GridColumn as Column} from "@progress/kendo-react-grid";
import * as _ from "lodash";
import {GoogleCloudPI, ReportsAPI} from "../../api/service";
import SimpleBackdrop from "../common/SimpleBackdrop";
import IconButton from "@material-ui/core/IconButton";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ReplayIcon from '@material-ui/icons/Replay';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Tooltip from "@material-ui/core/Tooltip";
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import LocationList from "../LocationList";
import FullScreenDialog from "../common/FullScreen";
import AlertDialog from "../common/AlertDialog";
import SnackbarContentWrapper from "../common/SnackBar";
import Snackbar from "@material-ui/core/Snackbar";

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    currencies,
    caGregorian,
    dateFields,
    timeZoneNames
);


loadMessages(esMessages, 'es-ES');

class ProjectList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data: [],
            originalData: [],
            skip: 0,
            take: 5,
            filter: {
                logic: "and",
                filters: [
                    {field: "name", operator: "contains", value: ""}
                ]
            },
            fullScreenDialog: {
                open: false,
                title: '',
                content: null
            },
            openAlertDialog: false,
            file: null,
            fileName: '',
            snackbar: {
                open: false,
                type: 'info',
                message: ''
            },
            currentProjectId: 0
        };
    }

    // <editor-fold defaultstate="collapsed" desc="Start">
    pageChange = (event) => {
        this.setState({
            skip: event.page.skip,
            take: event.page.take
        });
    };

    getProjectList = () => {
        let url = "/adm/reports/project/list";
        let data = {};
        ReportsAPI.post(url, data)
            .then(response => {
                if (response.success) {
                    this.getProjectListGoogleCloud(response.data);
                } else {
                    this.setState({isLoading: false});
                    this.handleOpenSnackbar('Ocurrió un error al consultar los proyectos', 'error');
                }
            })
            .catch(error => console.log(error))
    };

    getProjectListGoogleCloud = (dataRDS) => {
        let url = "/adm/google-cloud/projects/list";
        let data = {};
        GoogleCloudPI.post(url, data)
            .then(response => {
                if (response.success) {
                    let dataFirebase = response.data;
                    for (let z = 0; z < dataRDS.length; z++) {
                        let element = this.handleFindId(dataRDS[z].id, dataFirebase);
                        let exists = element !== undefined;
                        dataRDS[z].exists = exists;
                        let url = '';
                        if (exists) {
                            url = 'https://storage.cloud.google.com/' + dataRDS[z].bucketNameFirestore + '/' + element.currentReportBase;
                        }
                        dataRDS[z].url = url;
                    }
                    this.setState({
                        data: dataRDS,
                        originalData: dataRDS
                    });
                } else {
                    this.handleOpenSnackbar('Ocurrió un error al consultar los proyectos en GC', 'error');
                }
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoading: false}))
    };

    handleFindId = (id, array) => {
        let element = undefined;
        for (let i = 0; i < array.length; i++) {
            if (parseInt(id) === parseInt(array[i].id)) {
                element = array[i];
                break;
            }
        }
        return element;
    };

    componentDidMount() {
        this.getProjectList();
    }

    // </editor-fold>

    // <editor-fold defaultstate="collapsed" desc="Download report">
    handleDownloadByURL = (url) => {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.click();
        link.remove();
    };

    // </editor-fold>

    // <editor-fold defaultstate="collapsed" desc="MessageAlertInfo fullscreen">
    handleOpenFullScreenDialog = (projectId, projectName) => {
        this.setState({
            fullScreenDialog: {
                open: true,
                title: 'Ubicaciones',
                content: <LocationList projectId={projectId} projectName={projectName}/>
            }
        });
    };

    handleCloseFullScreenDialog = () => {
        this.setState({
            fullScreenDialog: {
                open: false,
                title: '',
                content: null,
                isQuestionnaire: false
            }
        });
    };

    // </editor-fold>

    // <editor-fold defaultstate="collapsed" desc="Upload template">
    handleOpenAlertDialog = (projectId) => {
        this.setState({
            openAlertDialog: true,
            currentProjectId: projectId
        });
    };

    handleCancelAlertDialog = () => {
        this.setState({
            openAlertDialog: false,
            file: null,
            fileName: '',
            currentProjectId: 0
        });
    };
    handleAcceptAlertDialog = () => {
        let document = this.state.file;
        let projectId = this.state.currentProjectId;
        if (document === null) {
            this.handleOpenSnackbar('Selecciona un archivo', 'error');
        } else {
            let fileName = document.name;
            let ext = fileName.split('.').pop();
            if (ext === 'docx') {
                this.setState({isLoading: true});
                const formData = new FormData();
                formData.append('document', document);
                formData.append('projectId', projectId);
                let url = '/adm/google-cloud/upload-report-base';
                this.handleCancelAlertDialog();
                GoogleCloudPI.postFile(url, formData)
                    .then(response => {
                        if (response.success) {
                            let timestamp = response.data.timestamp;
                            this.generateStructure(projectId, timestamp);
                        } else {
                            this.setState({isLoading: false});
                            this.handleOpenSnackbar('Ocurrió un error al cargar el archivo base', 'error');
                        }
                    })
                    .catch(error => console.log(error))
            } else {
                this.handleOpenSnackbar('Archivo no válido, los archivos permitidos son con extensión .docx', 'error');
            }

        }
    };
    handleChangeFile = (event) => {
        this.setState({
            file: event.target.files[0],
            fileName: event.target.files[0].name
        });
    };

    generateStructure = (projectId, timestamp) => {
        let url = "/adm/google-cloud/structure/add";
        let data = {
            projectId: projectId,
            timestamp: timestamp
        };
        GoogleCloudPI.post(url, data)
            .then(response => {
                if (response.success) {
                    this.getProjectList();
                    this.handleOpenSnackbar('El documento se almacenó correctamente', 'success');
                } else {
                    this.setState({isLoading: false});
                    this.handleOpenSnackbar('Ocurrió un error al generar la estrutura', 'error');
                }
            })
            .catch(error => console.log(error))
    };

    // </editor-fold>

    // <editor-fold defaultstate="collapsed" desc="Snackbar">
    handleCloseSnackbar = () => {
        this.setState({
            snackbar: {
                open: false,
                type: 'error',
                message: ''
            }
        });
    };

    handleOpenSnackbar = (message, type) => {
        this.setState({
            snackbar: {
                open: true,
                type: type,
                message: message
            }
        });
    };

    // </editor-fold>

    render() {
        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.snackbar.open}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackbar}
                >
                    <SnackbarContentWrapper
                        onClose={this.handleCloseSnackbar}
                        variant={this.state.snackbar.type}
                        message={this.state.snackbar.message}
                    />
                </Snackbar>
                <AlertDialog
                    open={this.state.openAlertDialog}
                    handleCancel={this.handleCancelAlertDialog}
                    handleAccept={this.handleAcceptAlertDialog}
                    handleChangeFile={this.handleChangeFile}
                    fileName={this.state.fileName}
                />
                <SimpleBackdrop
                    open={this.state.isLoading}
                />
                <AppBar position="fixed">
                    <Toolbar>
                        <Typography variant="h6">
                            {'Proyectos'}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <React.Fragment>
                    <CssBaseline/>
                    <Container maxWidth="xl">
                        {this.state.isLoading ? '' :
                            <div className={'pt-5'}>
                                <div className={'pt-4'}>
                                    <SimpleCard
                                        cardHeader={
                                            {
                                                title: '',
                                                subheader: ''
                                            }
                                        }
                                        cardContent={
                                            <LocalizationProvider language="es-ES">
                                                <IntlProvider locale="es">
                                                    <Grid
                                                        data={this.state.data.slice(this.state.skip, this.state.take + this.state.skip)}
                                                        skip={this.state.skip}
                                                        take={this.state.take}
                                                        total={this.state.data.length}
                                                        style={{height: "auto"}}
                                                        pageable={true}
                                                        onPageChange={this.pageChange}
                                                        filterable
                                                        filter={this.state.filter}
                                                        onFilterChange={(e) => {
                                                            let filterValue = '';
                                                            if (e.filter !== null) {
                                                                filterValue = e.filter.filters[0].value;
                                                            }
                                                            if (filterValue === '') {
                                                                let originalData = this.state.originalData;
                                                                this.setState({
                                                                    data: originalData,
                                                                    filter: e.filter
                                                                });
                                                            } else {
                                                                let filteredData = _.filter(this.state.originalData, function (o) {
                                                                    let text = o.name.toLowerCase();
                                                                    return text.indexOf(filterValue.toLowerCase()) > -1;
                                                                });
                                                                this.setState({
                                                                    data: filteredData,
                                                                    filter: e.filter
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        <Column field="id" title="Código" filterable={false}
                                                                className={'text-center'}/>
                                                        <Column field="name" title="Nombre"/>
                                                        <Column field="owner" title="Responsable" filterable={false}/>
                                                        <Column field="startDate" title="Fecha de inicio"
                                                                filterable={false} className={'text-center'}/>
                                                        <Column field="endDate" title="Fecha fin" filterable={false}
                                                                className={'text-center'}
                                                        />
                                                        <Column title="Plantilla" filterable={false}
                                                                className={'text-center'}
                                                                cell={props => {
                                                                    let item = props.dataItem;
                                                                    let contentTD;
                                                                    if (item.exists) {
                                                                        contentTD = (
                                                                            <div>
                                                                                <Tooltip title={'Descargar'}>
                                                                                    <IconButton color="primary"
                                                                                                aria-label="download template"
                                                                                                component="span"
                                                                                                onClick={() => this.handleDownloadByURL(item.url)}
                                                                                    >
                                                                                        <CloudDownloadIcon/>
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                <Tooltip title={'Actualizar'}>
                                                                                    <IconButton color="primary"
                                                                                                aria-label="refresh template"
                                                                                                component="span"
                                                                                                onClick={() => this.handleOpenAlertDialog(item.id)}
                                                                                    >
                                                                                        <ReplayIcon/>
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </div>
                                                                        )
                                                                    } else {
                                                                        contentTD = <Tooltip title={'Cargar'}>
                                                                            <IconButton color="primary"
                                                                                        aria-label="upload template"
                                                                                        component="span"
                                                                                        onClick={() => this.handleOpenAlertDialog(item.id)}
                                                                            >
                                                                                <CloudUploadIcon/>
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    }

                                                                    return <td className="k-command-cell text-center">
                                                                        {contentTD}
                                                                    </td>
                                                                }}
                                                        />
                                                        <Column title="Ubicaciones" filterable={false}
                                                                className={'text-center'}
                                                                cell={props => {
                                                                    let item = props.dataItem;
                                                                    return <td className="k-command-cell text-center">
                                                                        <Tooltip title={'Ubicaciones'}>
                                                                            <IconButton color="primary"
                                                                                        aria-label="locations"
                                                                                        component="span"
                                                                                        disabled={!item.exists}
                                                                                        onClick={() => this.handleOpenFullScreenDialog(item.id, item.name)}
                                                                            >
                                                                                <PersonPinCircleIcon/>
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </td>
                                                                }}
                                                        />
                                                    </Grid>
                                                </IntlProvider>
                                            </LocalizationProvider>
                                        }
                                    />
                                </div>
                            </div>
                        }
                    </Container>
                </React.Fragment>
                <FullScreenDialog
                    handleCloseFullScreenDialog={this.handleCloseFullScreenDialog}
                    open={this.state.fullScreenDialog.open}
                    title={this.state.fullScreenDialog.title}
                >
                    {this.state.fullScreenDialog.content}
                </FullScreenDialog>
            </div>
        );
    }
}

export default ProjectList;