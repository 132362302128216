import axios from 'axios';

const BASE_URL_REPORTS = process.env.REACT_APP_API_URL;

export const API_REPORTS = axios.create({
    baseURL: BASE_URL_REPORTS
});

const BASE_URL_GOOGLE_CLOUD = process.env.REACT_APP_API_URL;

export const API_GOOGLE_CLOUD = axios.create({
    baseURL: BASE_URL_GOOGLE_CLOUD
});
