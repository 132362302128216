import React from "react";
import SimpleCard from "../../common/SimpleCard";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import {IntlProvider, load, LocalizationProvider, loadMessages} from '@progress/kendo-react-intl';
import esMessages from '../../../globalization/es.json';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';
import numbers from 'cldr-numbers-full/main/es/numbers.json';
import currencies from 'cldr-numbers-full/main/es/currencies.json';
import caGregorian from 'cldr-dates-full/main/es/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/es/dateFields.json';
import timeZoneNames from 'cldr-dates-full/main/es/timeZoneNames.json';
import {Grid, GridColumn as Column, GridDetailRow} from "@progress/kendo-react-grid";
import * as _ from "lodash";
import {ReportsAPI} from "../../../api/service";
import SimpleBackdrop from "../../common/SimpleBackdrop";
import SnackbarContentWrapper from "../../common/SnackBar";
import Snackbar from "@material-ui/core/Snackbar";
import MessageAlertInfo from "../../common/MessageAlertInfo";
import {List} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DescriptionIcon from '@material-ui/icons/Description';
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    currencies,
    caGregorian,
    dateFields,
    timeZoneNames
);


loadMessages(esMessages, 'es-ES');

class ProjectList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data: [],
            originalData: [],
            skip: 0,
            take: 5,
            filter: {
                logic: "and",
                filters: [
                    {field: "name", operator: "contains", value: ""}
                ]
            },
            snackbar: {
                open: false,
                type: 'info',
                message: ''
            },
            currentProjectId: 0
        };
    }

    // <editor-fold defaultstate="collapsed" desc="Start">
    pageChange = (event) => {
        this.setState({
            skip: event.page.skip,
            take: event.page.take
        });
    };

    getProjectList = () => {
        let url = "/adm/reports/project-list";
        let data = {
            userId: this.props.userId
        };
        ReportsAPI.post(url, data)
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data,
                        originalData: response.data
                    });
                } else {
                    this.handleOpenSnackbar('Ocurrió un error al consultar los proyectos: ' + response.message, 'error');
                }
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoading: false}))
    };

    componentDidMount() {
        this.getProjectList();
    }

    expandChange = (event) => {
        event.dataItem.expanded = !event.dataItem.expanded;
        this.forceUpdate();
    };

    // </editor-fold>

    // <editor-fold defaultstate="collapsed" desc="Snackbar">
    handleCloseSnackbar = () => {
        this.setState({
            snackbar: {
                open: false,
                type: 'info',
                message: ''
            }
        });
    };

    handleOpenSnackbar = (message, type) => {
        this.setState({
            snackbar: {
                open: true,
                type: type,
                message: message
            }
        });
    };

    // </editor-fold>

    render() {
        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.snackbar.open}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackbar}
                >
                    <SnackbarContentWrapper
                        onClose={this.handleCloseSnackbar}
                        variant={this.state.snackbar.type}
                        message={this.state.snackbar.message}
                    />
                </Snackbar>

                <SimpleBackdrop
                    open={this.state.isLoading}
                />
                <AppBar position="fixed">
                    <Toolbar>
                        <Typography variant="h6">
                            {'Proyectos'}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <React.Fragment>
                    <CssBaseline/>
                    <Container maxWidth="xl">
                        {this.state.isLoading ? '' :
                            <div className={'pt-5'}>
                                <div className={'pt-4'}>
                                    <SimpleCard
                                        cardHeader={
                                            {
                                                title: '',
                                                subheader: ''
                                            }
                                        }
                                        cardContent={
                                            <LocalizationProvider language="es-ES">
                                                <IntlProvider locale="es">
                                                    <Grid
                                                        data={this.state.data.slice(this.state.skip, this.state.take + this.state.skip)}
                                                        skip={this.state.skip}
                                                        take={this.state.take}
                                                        total={this.state.data.length}
                                                        style={{height: "auto"}}
                                                        pageable={true}
                                                        onPageChange={this.pageChange}
                                                        filterable
                                                        filter={this.state.filter}
                                                        detail={ItemDetail}
                                                        expandField="expanded"
                                                        onExpandChange={this.expandChange}
                                                        onFilterChange={(e) => {
                                                            let filterValue = '';
                                                            if (e.filter !== null) {
                                                                filterValue = e.filter.filters[0].value;
                                                            }
                                                            if (filterValue === '') {
                                                                let originalData = this.state.originalData;
                                                                this.setState({
                                                                    data: originalData,
                                                                    filter: e.filter
                                                                });
                                                            } else {
                                                                let filteredData = _.filter(this.state.originalData, function (o) {
                                                                    let text = o.projectName.toLowerCase();
                                                                    return text.indexOf(filterValue.toLowerCase()) > -1;
                                                                });
                                                                this.setState({
                                                                    data: filteredData,
                                                                    filter: e.filter
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        <Column field="projectId" title="Código" filterable={false}
                                                                className={'text-center'}/>
                                                        <Column field="projectName" title="Nombre"/>
                                                        <Column field="startDate" title="Fecha de inicio"
                                                                filterable={false} className={'text-center'}/>
                                                        <Column field="endDate" title="Fecha fin" filterable={false}
                                                                className={'text-center'}
                                                        />
                                                    </Grid>
                                                </IntlProvider>
                                            </LocalizationProvider>
                                        }
                                    />
                                </div>
                            </div>
                        }
                    </Container>
                </React.Fragment>
            </div>
        );
    }
}

class ItemDetail extends GridDetailRow {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            messageAlertInfo: {
                open: false,
                title: '',
                message: '',
                handleAccept: null,
                labelButtonAccept: ''
            }
        }
    }

    // <editor-fold defaultstate="collapsed" desc="Dialog">
    handleOpenMessageAlertInfo = (title, message, handleAccept, labelButtonAccept) => {
        this.setState({
            messageAlertInfo: {
                open: true,
                title: title,
                message: message,
                handleAccept: handleAccept,
                labelButtonAccept: labelButtonAccept
            }
        });
    };

    handleCloseAlertDialog = () => {
        this.setState({
            messageAlertInfo: {
                open: false,
                title: '',
                message: '',
                handleAccept: null,
                labelButtonAccept: ''
            }
        });
    };

    // </editor-fold>
    handleRequestReport = (projectId, projectName, task, userId) => {
        this.setState({isLoading: true});
        let url = "/adm/reporter/solicitor/report";
        let data = {
            projectId: projectId,
            projectName: projectName,
            taskId: task.taskId,
            taskName: task.taskName,
            surveyId: task.surveyId,
            surveyName: task.surveyName,
            userId: userId
        };
        ReportsAPI.post(url, data)
            .then(response => {
                if (response.success) {
                    let title = 'Solicitud enviada';
                    let message = 'Tu solicitud de reporte ha sido envíada, en breve recibirás la liga de descarga en tu correo registrado. Por seguridad la liga solo estará disponible por una hora.';
                    this.handleOpenMessageAlertInfo(title, message, this.handleCloseAlertDialog, "Aceptar");
                } else {
                    this.handleOpenMessageAlertInfo("Error", response.message, this.handleCloseAlertDialog, "Aceptar");
                }
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoading: false}))
    };

    render() {
        let dataItem = this.props.dataItem;
        let projectId = dataItem.projectId;
        let projectName = dataItem.projectName;
        let userId = dataItem.userId;
        let taskList = dataItem.taskList;
        return (
            <section>
                <SimpleBackdrop
                    open={this.state.isLoading}
                />
                <MessageAlertInfo
                    open={this.state.messageAlertInfo.open}
                    title={this.state.messageAlertInfo.title}
                    message={this.state.messageAlertInfo.message}
                    handleAccept={this.state.messageAlertInfo.handleAccept}
                    labelButtonAccept={this.state.messageAlertInfo.labelButtonAccept}
                />
                <div className={"row"}>
                    <div className={'col-md-12'}>
                        <List dense={true}>
                            {
                                taskList.map(item => {
                                    return <div key={item.taskId + '-' + item.surveyId }>
                                        <ListItem>
                                            <ListItemText
                                                primary={item.taskId + " " + item.taskName}
                                                secondary={item.surveyId + " " + item.surveyName}
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton edge="end" aria-label="delete"
                                                            onClick={() => this.handleRequestReport(projectId, projectName, item, userId)}>
                                                    <Tooltip title={'Solicitar reporte'} children={<DescriptionIcon/>}/>
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <Divider variant="inset" component="li"/>
                                    </div>
                                })
                            }
                        </List>
                    </div>
                </div>
            </section>
        );
    }
}

export default ProjectList;