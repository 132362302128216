import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Socioeconomic from "./containers/Socioeconomic";
import General from "./containers/General";
import Conekta from "./containers/Conekta";

function App() {
    return (
        <Router>
            <div className="">
                <Switch>
                    <Route path="/socioeconomic" component={Socioeconomic}/>
                    <Route path="/general/:userId" component={General}/>
                    <Route path="/conekta/:userId" component={Conekta}/>
                </Switch>
            </div>
        </Router>
    );
}

export default App;
