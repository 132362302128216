import React from 'react';
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar/index";
import Typography from "@material-ui/core/Typography/index";
import Dialog from "@material-ui/core/Dialog/index";
import Slide from "@material-ui/core/Slide/index";
import fullScreenDialogStyles from "./styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenDialog = (props) => {
    const classes = fullScreenDialogStyles();

    return (
        <Dialog fullScreen open={props.open}
                TransitionComponent={Transition}
                style={{zIndex: 9999}}
        >
            <AppBar position="fixed" color={"primary"}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="close" onClick={props.handleCloseFullScreenDialog}>
                        <CloseIcon/>
                    </IconButton>
                        <Typography variant="h6" component={'div'}
                                    className={classes.title}>
                            {props.title}
                        </Typography>
                    <div className={classes.grow}/>
                </Toolbar>
            </AppBar>
            <div className={"pt-4"}>
                {props.children}
            </div>
        </Dialog>
    );
};

export default FullScreenDialog;
