import React from "react";
import ProjectList from "../components/general/ProjectList";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";

class General extends React.Component{
    render() {
        let userId = atob(this.props.match.params.userId);
        const theme = createMuiTheme({
            palette: {
                primary: {
                    main: '#FF995B',
                    contrastText: '#FFFFFF'
                }
            }
        });
        return (
            <MuiThemeProvider theme={theme}>
                <ProjectList userId={userId}/>
            </MuiThemeProvider>
        );
    }
}
export default General;