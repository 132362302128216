import React, {useRef} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Typography} from "@material-ui/core";

export default function AlertDialog(props) {
    const hiddenInputFile = useRef(null);
    return (
        <div>
            <Dialog
                open={props.open}
                onClose={ (event)=> event.preventDefault()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Cargar plantilla"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Selecciona el documento .docx a cargar
                    </DialogContentText>
                    <div>
                        <Button variant="outlined" color="primary" onClick={e => hiddenInputFile.current && hiddenInputFile.current.click()}>
                            Seleccionar archivo
                        </Button>
                        <input type={'file'}
                               onChange={props.handleChangeFile}
                               ref={hiddenInputFile}
                               style={{display: "none"}}
                               accept={'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'}
                        />
                        <Typography variant={'body2'} className={'mt-2 mb-2'}>{props.fileName}</Typography>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleCancel} color="secondary">
                        Cancelar
                    </Button>
                    <Button onClick={props.handleAccept} color="primary">
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}