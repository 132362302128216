import {API_REPORTS, API_GOOGLE_CLOUD} from './config';

export const ReportsAPI = {
    post: (url, data) => new Promise(
        (resolve, reject) => {
            API_REPORTS.post(url, data)
                .then(
                    res => res.data
                )
                .then(
                    data => resolve(data)
                )
                .catch(
                    err => reject(err)
                )
        }
    )
};

export const GoogleCloudPI = {
    post: (url, data) => new Promise(
        (resolve, reject) => {
            API_GOOGLE_CLOUD.post(url, data)
                .then(
                    res => res.data
                )
                .then(
                    data => resolve(data)
                )
                .catch(
                    err => reject(err)
                )
        }
    ),
    postFile: (url, data) => new Promise(
        (resolve, reject) => {
            API_GOOGLE_CLOUD.post(url, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(
                    res => res.data
                )
                .then(
                    data => resolve(data)
                )
                .catch(
                    err => reject(err)
                )
        }
    )
};
