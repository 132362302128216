import React from "react";
import {ReportsAPI} from "../../../api/service";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "../../common/SnackBar";
import SimpleBackdrop from "../../common/SimpleBackdrop";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import SimpleCard from "../../common/SimpleCard";
import {IntlProvider, load, loadMessages, LocalizationProvider} from "@progress/kendo-react-intl";
import {Grid} from "@progress/kendo-react-grid";
import * as _ from "lodash";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import esMessages from "../../../globalization/es.json";
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';
import numbers from 'cldr-numbers-full/main/es/numbers.json';
import currencies from 'cldr-numbers-full/main/es/currencies.json';
import caGregorian from 'cldr-dates-full/main/es/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/es/dateFields.json';
import timeZoneNames from 'cldr-dates-full/main/es/timeZoneNames.json';
import DescriptionIcon from "@material-ui/icons/Description";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import MessageAlertInfo from "../../common/MessageAlertInfo";

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    currencies,
    caGregorian,
    dateFields,
    timeZoneNames
);


loadMessages(esMessages, 'es-ES');

class SurveyList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data: [],
            originalData: [],
            snackbar: {
                open: false,
                type: 'info',
                message: ''
            },
            skip: 0,
            take: 5,
            filter: {
                logic: "and",
                filters: [
                    {field: "name", operator: "contains", value: ""}
                ]
            },
            messageAlertInfo: {
                open: false,
                title: '',
                message: '',
                handleAccept: null,
                labelButtonAccept: ''
            }
        }

    }

    pageChange = (event) => {
        this.setState({
            skip: event.page.skip,
            take: event.page.take
        });
    };

    // <editor-fold defaultstate="collapsed" desc="Snackbar">
    handleCloseSnackbar = () => {
        this.setState({
            snackbar: {
                open: false,
                type: 'info',
                message: ''
            }
        });
    };

    handleOpenSnackbar = (message, type) => {
        this.setState({
            snackbar: {
                open: true,
                type: type,
                message: message
            }
        });
    };

    // </editor-fold>

    getSurveyList = () => {
        let url = "/adm/reports/survey-list";
        let data = {
            userId: this.props.userId
        };
        ReportsAPI.post(url, data)
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data,
                        originalData: response.data
                    });
                } else {
                    this.handleOpenSnackbar('Ocurrió un error al consultar las encuestas: ' + response.message, 'error');
                }
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoading: false}))
    };

    componentDidMount() {
        this.getSurveyList();
    }

    // <editor-fold defaultstate="collapsed" desc="Dialog">
    handleOpenMessageAlertInfo = (title, message, handleAccept, labelButtonAccept) => {
        this.setState({
            messageAlertInfo: {
                open: true,
                title: title,
                message: message,
                handleAccept: handleAccept,
                labelButtonAccept: labelButtonAccept
            }
        });
    };

    handleCloseAlertDialog = () => {
        this.setState({
            messageAlertInfo: {
                open: false,
                title: '',
                message: '',
                handleAccept: null,
                labelButtonAccept: ''
            }
        });
    };
    // </editor-fold>
    handleRequestReport = (surveyId, surveyName) => {
        this.setState({isLoading: true});
        let url = "/adm/reporter/solicitor/report";
        let data = {
            surveyId: surveyId,
            surveyName: surveyName,
            userId: this.props.userId,
            requestType: 'conekta'
        };
        ReportsAPI.post(url, data)
            .then(response => {
                if (response.success) {
                    let title = 'Solicitud enviada';
                    let message = 'Tu solicitud de reporte de la encuesta ';
                    message = message + surveyName;
                    message = message + ' ha sido envíada, en breve recibirás el link de descarga en tu correo registrado. Por seguridad el link solo estará disponible por una hora.';
                    this.handleOpenMessageAlertInfo(title, message, this.handleCloseAlertDialog, "Aceptar");
                } else {
                    this.handleOpenMessageAlertInfo("Error", response.message, this.handleCloseAlertDialog, "Aceptar");
                }
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({isLoading: false}))
    };

    render() {
        return (
            <div>
                <MessageAlertInfo
                    open={this.state.messageAlertInfo.open}
                    title={this.state.messageAlertInfo.title}
                    message={this.state.messageAlertInfo.message}
                    handleAccept={this.state.messageAlertInfo.handleAccept}
                    labelButtonAccept={this.state.messageAlertInfo.labelButtonAccept}
                />
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.snackbar.open}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackbar}
                >
                    <SnackbarContentWrapper
                        onClose={this.handleCloseSnackbar}
                        variant={this.state.snackbar.type}
                        message={this.state.snackbar.message}
                    />
                </Snackbar>

                <SimpleBackdrop
                    open={this.state.isLoading}
                />
                <AppBar position="fixed">
                    <Toolbar>
                        <Typography variant="h6">
                            {'Encuestas'}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <React.Fragment>
                    <CssBaseline/>
                    <Container maxWidth="xl">
                        {this.state.isLoading ? '' :
                            <div className={'pt-5'}>
                                <div className={'pt-4'}>
                                    <SimpleCard
                                        cardHeader={
                                            {
                                                title: '',
                                                subheader: ''
                                            }
                                        }
                                        cardContent={
                                            <LocalizationProvider language="es-ES">
                                                <IntlProvider locale="es">
                                                    <Grid
                                                        data={this.state.data.slice(this.state.skip, this.state.take + this.state.skip)}
                                                        skip={this.state.skip}
                                                        take={this.state.take}
                                                        total={this.state.data.length}
                                                        style={{height: "auto"}}
                                                        pageable={true}
                                                        onPageChange={this.pageChange}
                                                        filterable
                                                        filter={this.state.filter}
                                                        onFilterChange={(e) => {
                                                            let filterValue = '';
                                                            if (e.filter !== null) {
                                                                filterValue = e.filter.filters[0].value;
                                                            }
                                                            if (filterValue === '') {
                                                                let originalData = this.state.originalData;
                                                                this.setState({
                                                                    data: originalData,
                                                                    filter: e.filter
                                                                });
                                                            } else {
                                                                let filteredData = _.filter(this.state.originalData, function (o) {
                                                                    let text = o.name.toLowerCase();
                                                                    return text.indexOf(filterValue.toLowerCase()) > -1;
                                                                });
                                                                this.setState({
                                                                    data: filteredData,
                                                                    filter: e.filter
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        <Column field="id"
                                                                title="Código"
                                                                filterable={false}
                                                                className={'text-center'}/>
                                                        <Column field="name"
                                                                title="Nombre"/>
                                                        <Column field="creationDate"
                                                                title="Fecha de creación"
                                                                filterable={false}
                                                                className={'text-center'}
                                                        />
                                                        <Column filterable={false}
                                                                title="Reporte"
                                                                cell={props => {
                                                                    let item = props.dataItem;
                                                                    return <td className="k-command-cell text-center">
                                                                        <IconButton edge="end" aria-label="report"
                                                                                    onClick={() => this.handleRequestReport(item.id, item.name)}
                                                                        >
                                                                            <DescriptionIcon/>
                                                                        </IconButton>
                                                                    </td>
                                                                }}
                                                        />
                                                    </Grid>
                                                </IntlProvider>
                                            </LocalizationProvider>
                                        }
                                    />
                                </div>
                            </div>
                        }
                    </Container>
                </React.Fragment>

            </div>
        );
    }

}

export default SurveyList;