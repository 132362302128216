import React from 'react';
import {Card} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";

function SimpleCard({cardHeader, cardContent, cardActions}) {
    return (
        <Card square={true}
              variant={"outlined"}
              style={{overflow: "inherit"}}
              className={"mb-3"}
              elevation={1}
        >
            <CardHeader
                {...cardHeader}
            />
            <CardContent>
                {cardContent}
            </CardContent>
            <CardActions>
                {cardActions}
            </CardActions>
        </Card>
    );
}

export default SimpleCard;
